import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import BonusList from 'components/bonuslist';
import Container from '@material-ui/core/Container';
import Hero from 'components/hero';

export const IndexPageTemplate = ({
  title,
  heading,
  content,
  image,
  companies,
}) => (
  <Container maxWidth="xl" style={{ marginTop: 32 }}>
    <Hero title={heading} content={content} image={image} />
    <BonusList items={companies} />
  </Container>
);

const IndexPage = ({ data }) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;
  const { edges: companies } = data.allMarkdownRemark && data.allMarkdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        image={frontmatter.image}
        content={rawMarkdownBody}
        companies={companies}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      rawMarkdownBody
      frontmatter {
        title
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "company-page" } } }
    ) {
      edges {
        node {
          id
          rawMarkdownBody
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxHeight: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            features
            bonus {
              deposit
              money
              instructions
              odds
              turnover
              days
              link
              extra
            }
          }
        }
      }
    }
  }
`;
