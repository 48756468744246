import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/bonus-card';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  cardGrid: {
    // position: 'relative',
  },
});

const BonusList = ({ items, classes }) => (
  <div className={classNames(classes.layout, classes.cardGrid)}>
    <Grid container spacing={4} justify="center">
      {items.map((item, i) => (
        <Grid item key={i} sm={6} md={4} lg={3}>
          <Card item={item.node} />
        </Grid>
      ))}
    </Grid>
  </div>
);

BonusList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(BonusList);
