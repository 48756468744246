import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Img from 'gatsby-image';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MaterialLink from '@material-ui/core/Link';
import slugify from '../helpers/slugify';
import Divider from '@material-ui/core/Divider';
import BonusInfo from './bonus-info';

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  feature: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'end',
    padding: '2px 0',
  },
  featureIcon: {
    color: theme.palette.secondary.main,
    marginRight: 4,
  },
  chip: {
    // marginRight: theme.spacing.unit,
    height: 'auto',
    padding: '2px 0px',
    margin: '4px 8px 2px 0',
  },
}));

function MediaCard({ item }) {
  const { title, image, link, features, bonus } = item.frontmatter;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        // action={
        //   <IconButton aria-label="Add to favorites">
        //     <FavoriteIcon />
        //   </IconButton>
        // }
        title={title}
      />
      {bonus && bonus.link ? (
        <MaterialLink
          href={bonus.link}
          alt={title}
          target="_blank"
          rel="noopener"
        >
          <Img
            fluid={
              image && image.childImageSharp ? image.childImageSharp.fluid : {}
            }
            alt={title}
            className={classes.media}
          />
        </MaterialLink>
      ) : (
        <Img
          fluid={
            image && image.childImageSharp ? image.childImageSharp.fluid : {}
          }
          alt={title}
          className={classes.media}
        />
      )}

      <CardContent>
        <BonusInfo bonus={bonus} />
      </CardContent>
      <CardActions>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        {/* <IconButton aria-label="Share">
          <ShareIcon />
        </IconButton> */}
        <Button component={GatsbyLink} to={`/companies/${slugify(title)}`}>
          Läs mer
        </Button>
        <Button
          color="primary"
          variant="contained"
          component={MaterialLink}
          href={bonus.link}
          alt={title}
          target="_blank"
          rel="noopener"
        >
          Gå till sida
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <CardContent>
          <Typography variant="subtitle2" gutterBottom>
            <ul style={{ marginBottom: 8 }}>
              {features &&
                features.map((feature, i) => (
                  <li className={classes.feature} key={i}>
                    <CheckCircle className={classes.featureIcon} /> {feature}
                  </li>
                ))}
            </ul>
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Instruktioner
          </Typography>
          <Typography variant="body2">
            {bonus ? bonus.instructions : 'No instructions'}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
export default MediaCard;
